import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";
import {
  LayoutSplashScreen,
  // ContentRoute
} from "../_metronic/layout";
import { PATH } from "./config";
import { WEB_PAGES } from "./pages";
import { ROLE_POLICES } from "./constant";

export default function BasePage() {
  let policesData = useSelector((state) => state?.clinicsReducer);
  const isPermissionAllowed = (value) => {
    return policesData?.getSidebarPolicesByRoleIdData?.[0]?.policies
      ?.filter((items) => items?.value === value).length > 0

  }
  const combinedData = ROLE_POLICES.filter((item) =>
    policesData?.getSidebarPolicesByRoleIdData?.[0]?.policies?.some(
      (subItem) => subItem.value === item.value
    )
  );


  const { user } = useSelector((state) => state.auth);



  // useEffect(() => {
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect
  let location = useLocation();
  // eslint-disable-next-line
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* <Redirect exact from="/" to="/dashboard" /> */}
        {
          combinedData[combinedData.length-1]!==undefined?(<Redirect exact from="/" to= {combinedData[combinedData.length-1].path}/>) :""
          // user.data.roles[0] === "DHISAdmin" ? (
          //     <Redirect exact from="/" to={PATH.DHIS_REQUESTS} />
          // ) :
          // user.data.roles[0] === "DHISViewer" ? (
          //     <Redirect exact from="/" to="/dashboard" />
          //     // <Redirect exact from="/" to={PATH.DHIS_UPLOAD} />
          // )
          //  : user.data.roles[0] === "SuperAdmin" ||
          //   user.data.roles[0] === "Admin"||
          //   user.data.roles[0] === "ModerateAdmin"




          //   ? (
          //   /* Redirect from root URL to /dashboard. */
          //   <Redirect exact from="/" to="/dashboard" />
          // ):user.data.roles[0] === "DHISAdminAndViewer" ? (
          //   <Redirect exact from="/" to="/dhisupload" />
          //   ) : user.data.roles[0] === "Doctor" ? (
          //   <Redirect exact from="/" to={PATH.PATIENT_LIST} />
          // ) : user.data.roles[0] === "AnalyticsManager"? (<Redirect exact from="/" to="/dashboard" />
          // ) :""

        }

        {/* Dashboards */}
        <Route
          path={PATH.MAIN_DASHBOARD}
          component={WEB_PAGES.MAIN_DASHBOARD}
          exact
        />
        <Route
          path={PATH.IMMUNIZATION_DASHBOARD}
          component={WEB_PAGES.IMMUNIZATION_DASHBOARD}
          exact
        />
        <Route
          path={PATH.ANC_DASHBOARD}
          component={WEB_PAGES.ANC_DASHBOARD}
          exact
        />
        <Route
          path={PATH.ANC_SERVICES}
          component={WEB_PAGES.ANC_SERVICES}
          exact
        />

        {/* Clinics List */}
        <Route
          path={PATH.CLINIC_LIST}
          component={WEB_PAGES.CLINIC_LIST}
          exact
        />
        <Route path={PATH.CLINIC_ADD} component={WEB_PAGES.CLINIC_ADD} exact />
        <Route
          path={PATH.CLINIC_EDIT}
          component={WEB_PAGES.CLINIC_EDIT}
          exact
        />
        <Route
          path={PATH.CLINIC_VIEW}
          component={WEB_PAGES.CLINIC_VIEW}
          exact
        />
        <Route
          path={PATH.ROLES_AND_PERMISSIONS}
          component={WEB_PAGES.ROLES_AND_PERMISSIONS}
          exact
        />
        <Route
          path={PATH.TALLY_VALIDATION}
          component={WEB_PAGES.TALLY_VALIDATION}
          exact
        />

        {/* Admin List */}
        <Route path={PATH.ADMIN_LIST} component={WEB_PAGES.ADMIN_LIST} exact />
        <Route path={PATH.ADMIN_ADD} component={WEB_PAGES.ADMIN_ADD} exact />
        <Route path={PATH.ADMIN_EDIT} component={WEB_PAGES.ADMIN_EDIT} exact />
        <Route path={PATH.ADMIN_VIEW} component={WEB_PAGES.ADMIN_VIEW} exact />

        {/* Doctor List */}
        <Route
          path={PATH.DOCTOR_LIST}
          component={WEB_PAGES.DOCTOR_LIST}
          exact
        />
        <Route path={PATH.DOCTOR_ADD} component={WEB_PAGES.DOCTOR_ADD} exact />
        <Route
          path={PATH.DOCTOR_EDIT}
          component={WEB_PAGES.DOCTOR_EDIT}
          exact
        />
        <Route
          path={PATH.DOCTOR_VIEW}
          component={WEB_PAGES.DOCTOR_VIEW}
          exact
        />

        {/* Caregiver List */}
        <Route
          path={PATH.CARE_GIVER_LIST}
          component={WEB_PAGES.CARE_GIVER_LIST}
          exact
        />
        <Route
          path={PATH.CARE_GIVER_ADD}
          component={WEB_PAGES.CARE_GIVER_ADD}
          exact
        />
        <Route
          path={PATH.CARE_GIVER_EDIT}
          component={WEB_PAGES.CARE_GIVER_EDIT}
          exact
        />
        <Route
          path={PATH.CARE_GIVER_VIEW}
          component={WEB_PAGES.CARE_GIVER_VIEW}
          exact
        />

        {/* Child list */}
        <Route path={PATH.CHILD_LIST} component={WEB_PAGES.CHILD_LIST} exact />
        <Route path={PATH.CHILD_EDIT} component={WEB_PAGES.CHILD_EDIT} exact />
        <Route path={PATH.CHILD_VIEW} component={WEB_PAGES.CHILD_VIEW} exact />
        <Route
          path={PATH.CHILD_IMMUNIZATION}
          component={WEB_PAGES.CHILD_IMMUNIZATION}
          exact
        />
        <Route
          path={PATH.ADD_OBSERVATION}
          component={WEB_PAGES.ADD_OBSERVATION}
          exact
        />
        <Route
          path={PATH.ADD_PRESCRIPTION}
          component={WEB_PAGES.ADD_PRESCRIPTION}
          exact
        />
        <Route
          path={PATH.EDIT_OBSERVATION}
          component={WEB_PAGES.EDIT_OBSERVATION}
          exact
        />
        <Route
          path={PATH.EDIT_PRESCRIPTION}
          component={WEB_PAGES.EDIT_PRESCRIPTION}
          exact
        />
        {/* Patient list */}
        <Route
          path={PATH.PATIENT_LIST}
          component={WEB_PAGES.PATIENT_LIST}
          exact
        />
        <Route
          path={PATH.PATIENT_VIEW}
          component={WEB_PAGES.PATIENT_VIEW}
          exact
        />

        {/* Learing Centre Section */}
        <Route
          path={PATH.LEARNING_CENTRE}
          component={WEB_PAGES.LEARNING_CENTRE}
          exact
        />
        <Route
          path={PATH.LEARNING_CENTRE_VIDEO_ADD}
          component={WEB_PAGES.LEARNING_CENTRE_VIDEO_ADD}
          exact
        />
        <Route
          path={PATH.LEARNING_CENTRE_VIDEO_VIEW}
          component={WEB_PAGES.LEARNING_CENTRE_VIDEO_VIEW}
          exact
        />

        <Route path={PATH.SCHEDULE} component={WEB_PAGES.SCHEDULE} exact />
        <Route
          path={PATH.SCHEDULE_ADD}
          component={WEB_PAGES.SCHEDULE_ADD}
          exact
        />
        <Route
          path={PATH.SCHEDULE_EDIT}
          component={WEB_PAGES.SCHEDULE_EDIT}
          exact
        />
        <Route
          path={PATH.LOGGING_ACTIVITY}
          component={WEB_PAGES.LOGGING_ACTIVITY}
          exact
        />

        {/* {Dhis Configuration} */}
        <Route
          path={PATH.DHIS_CONFIGURATION}
          component={WEB_PAGES.DHIS_CONFIGURATION}
          exact
        />
        <Route
          path={PATH.DHIS_UPLOAD}
          component={WEB_PAGES.DHIS_UPLOAD}
          exact
        />
        <Route
          path={PATH.DHIS_UPLOAD_ID}
          component={WEB_PAGES.DHIS_UPLOAD_ID}
          exact
        />
        <Route
          path={PATH.DHIS_REQUESTS}
          component={WEB_PAGES.DHIS_REQUESTS}
          exact
        />
        <Route
          path={PATH.DHIS_LOGS}
          component={WEB_PAGES.DHIS_LOGS}
          exact
        />
        <Route
          path={PATH.DHIS2_CONFIGURATION}
          component={WEB_PAGES.DHIS2_CONFIGURATION}
          exact
        />
        {/* <Redirect exact from="/" to="/dashboard" /> */}

        {/* <Redirect to="error/error-v1" /> */}
      </Switch>
    </Suspense>
  );
}
